import {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {useSelector} from "react-redux";
import {reloadMain, tenantData} from "../reducers/mainContentReducer";
import useHttp from "../hooks/useHttp";
import {Box, Button, Typography, useTheme} from "@mui/material";
import {tokens} from "../theme";
import PeriodPicker from "./PeriodPicker";
import DataLoader from "./DataLoader";
import {BarChart} from "@mui/x-charts/BarChart";
import * as React from "react";
import {LineChart} from "@mui/x-charts";
import CachedIcon from "@mui/icons-material/Cached";

const MonthlyTotalsWithTotalAmount = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const activeTenant = useSelector(tenantData)
    const {sendRequest} = useHttp();
    const [periodFrom, setPeriodFrom] = useState(null);
    const [periodTo, setPeriodTo] = useState(null);
    const [activePeriod, setActivePeriod] = useState('ALL');
    const [isLoading, setIsLoading] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0);
    const [state, setState] = useState({});
    const reload = useSelector(reloadMain)

    useEffect(() => {
        if (activeTenant?.id) {
            setIsLoading(true);
            let url = "tenant/transactions/" + activeTenant?.id + "/monthly_totals"
            if (periodFrom && periodTo) {
                const periodFromStr = new Date(periodFrom).toISOString().slice(0, 10)
                const periodToStr = new Date(periodTo).toISOString().slice(0, 10)
                url += `?date_from=${periodFromStr}&date_to=${periodToStr}`
            }
            sendRequest(
                {url},
                {
                    message: {
                        error: 'Nije moguće dovući potrošnju po mesecima!'
                    }
                },
                {
                    success: (res) => {
                        if (Object.keys(res).length === 0 && res.constructor === Object) {
                            setTotalAmount(0)
                            setState({})
                            setIsLoading(false)
                            return
                        }
                        const labels = [];
                        const totalAmounts = [];
                        setTotalAmount(res?.total_amount)
                        res.monthly_totals.forEach(item => {
                            labels.push(item.month);
                            totalAmounts.push(item.total_amount);
                        });
                        setState({"labels": labels, "totalAmounts": totalAmounts})
                        setIsLoading(false);
                    },
                    error: () => {
                        setIsLoading(false)
                    }

                }
            );
        }
    }, [activeTenant, sendRequest, periodFrom, periodTo, reload]);

    return (
        <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
        >
            <DataLoader isLoading={isLoading}>
                {totalAmount !== 0 ? (
                    <Box width={"100%"}>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Ukupna potrošnja
                        </Typography>
                        <Typography
                            variant="h3"
                            fontWeight="bold"
                            color={colors.greenAccent[500]}
                        >
                            RSD {totalAmount}
                        </Typography>
                        <PeriodPicker activePeriod={activePeriod} setActivePeriod={setActivePeriod}
                                      setPeriodFrom={setPeriodFrom} setPeriodTo={setPeriodTo} hidden={["1M"]}/>
                                        <LineChart
                    series={[
                        { data: state.totalAmounts }
                    ]}
                    xAxis={[{ data: state.labels, scaleType: 'band' }]}
                    height={200}
                />
                    </Box>
                ) : <Typography
                    variant="h4"
                    color={colors.greenAccent[500]}
                    sx={{ display: "inline-flex", alignItems: "center", mt: "15px" }}
                >
                    Nema podataka
                    <CachedIcon
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                        onClick={() => {
                            setActivePeriod('ALL')
                            setPeriodFrom(null);
                            setPeriodTo(null);
                        }}
                        fontSize="large"
                    />
                </Typography>}
            </DataLoader>
        </Box>
    );
}

export default MonthlyTotalsWithTotalAmount;