import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {useField, useFormikContext} from 'formik';
import Textfield from '../Textfield';

export default function AutocompleteWrapper({
                                                name,
                                                label,
                                                disabled,
                                                onChange,
                                                options,
                                                renderOption,
                                                renderOptionLabel,
                                                isOptionEqualToValue,
                                                filterOptions,
                                                required,
                                                ...otherProps
                                            }) {
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const configSelect = {
        ...field,
        ...otherProps,
        size: 'small',
        fullWidth: true
    };

    const configTextField = {};
    if (meta && meta.touched && meta.error) {
        configTextField.error = true;
        configTextField.helperText = meta.error;
    }

    return (
        <Autocomplete
            className="AutocompleteField"
            {...configSelect}
            disabled={disabled}
            onChange={(e, value) => {
                if (onChange) {
                    onChange(value);
                }
                setFieldValue(name, value);
            }}
            options={options}
            filterOptions={filterOptions}
            autoHighlight
            getOptionLabel={renderOptionLabel}
            renderOption={renderOption}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={(params) => (
                <Textfield
                    outOfFormikContext={true}
                    {...configTextField}
                    {...params}
                    label={label}
                    name={name}
                    required={required}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}
