import {createSlice} from '@reduxjs/toolkit'
import useMediaQuery from "@mui/material/useMediaQuery";

export const mainContentSlice = createSlice({
    name: 'mainContent',
    initialState: {
        reload: false,
        general: null,
        tenant: sessionStorage.getItem("tenant") ? JSON.parse(sessionStorage.getItem("tenant")) : null,
        tenantList: [],
        sidebarVisible: true,
        mobile: false,
        backdrop: {visible: false, text: null}
    },
    reducers: {
        reloadMainContent: (state) => {
            state.reload ^= true
        },
        setGeneral: (state, action) => {
            state.general = action.payload
        },
        toggleSidebar: (state) => {
            state.sidebarVisible = !state.sidebarVisible
        },
        setSidebarVisible: (state, action) => {
            state.sidebarVisible = action.payload
        },
        setTenant: (state, action) => {
            state.tenant = action.payload
            sessionStorage.setItem('tenant', JSON.stringify(action.payload))
        },
        setTenantList: (state, action) => {
            state.tenantList = action.payload
        },
        setMobile(state, action) {
            state.mobile = action.payload
        },
        setBackdrop: (state, action) => {
            state.backdrop = action.payload
        },
        showBackdrop: (state, action) => {
            state.backdrop.visible = true
            state.backdrop.text = action.payload
        },
        hideBackdrop: (state) => {
            state.backdrop.visible = false
            state.backdrop.text = null
        }
    }
})

export const {
    reloadMainContent, setGeneral,
    setTenant,
    hideBackdrop,
    setBackdrop,
    showBackdrop,
    toggleSidebar,
    setSidebarVisible,
    setMobile,
    setTenantList
} = mainContentSlice.actions

export default mainContentSlice.reducer

export const reloadMain = state => state.mainContent.reload
export const generalData = state => state.mainContent.general
export const tenantData = state => state.mainContent.tenant
export const backdropData = state => state.mainContent.backdrop
export const sidebarVisible = state => state.mainContent.sidebarVisible
export const mobile = state => state.mainContent.mobile
export const tenantsList = state => state.mainContent.tenantList