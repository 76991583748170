import React, {useRef, useState} from 'react'
import {Alert, Snackbar} from '@mui/material'

function Notification({ message, status, open, handleClose, duration }) {
    return (
        <Snackbar
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            open={open}
            autoHideDuration={duration ?? 5000} //calls onClose after 3500ms (3.5secs)
            onClose={handleClose}
        >
            <Alert variant="filled" severity={status}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export const NotificationContext = React.createContext()

export function NotificationProvider({ children }) {
    const [open, setOpen] = useState(false)
    const [messageData, setMessageData] = useState(undefined)
    const queueRef = useRef([])

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }

    function processQueue() {
        if (queueRef.current.length > 0) {
            setMessageData(queueRef.current.shift())
            setOpen(true)
        }
    }

    function createNotification(notification) {
        queueRef.current.push(notification)

        if (open) {
            processQueue()
        } else {
            // If not opened already, process your notification.
            processQueue()
        }
    }

    return (
        <NotificationContext.Provider value={{ createNotification }}>
            {children}
            <Notification
                {...messageData}
                open={open}
                handleClose={handleClose}
            />
        </NotificationContext.Provider>
    )
}