import {GridActionsCellItem} from "@mui/x-data-grid";
import {Box, Button, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import {openDrawer, setDrawerData} from "../../reducers/drawerReducer";
import {useEffect, useState} from "react";
import useHttp from "../../hooks/useHttp";
import {useDispatch, useSelector} from "react-redux";
import {reloadMain} from "../../reducers/mainContentReducer";
import DataGridWrapper from "../../forms/DataGrid";
import Header from "../../components/Header";
import CreateTenant from "../../components/CreateTenant";


const TenantOverview = () => {

    const [tenantList, setTenantList] = useState([])
    const reload = useSelector(reloadMain)
    const dispatch = useDispatch()
    const { error, isPending, sendRequest } = useHttp();

    const columns = [
        {
            field: "name",
            headerName: "Naziv radnje",
            flex: 0.5,
            cellClassName: "name-column--cell pointer",
        },
        {
            field: "address",
            headerName: "Adresa",
            width: 100,
            cellClassName: "name-column--cell",
        },
        {
            field: "city",
            headerName: "Grad",
            width: 100,
            cellClassName: "name-column--cell",
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: "Opcije",
            getActions: (params) => {
                    return [
                        <GridActionsCellItem icon={<Tooltip title="Izmeni"><EditIcon /></Tooltip>} onClick={() => {dispatch(setDrawerData(params.row)); dispatch(openDrawer('tenant'))}} label="Izmeni" showInMenu />,
                        <GridActionsCellItem icon={<Tooltip title="Obriši"><DeleteIcon /></Tooltip>} onClick={() => {}} label="Obriši" showInMenu />
                    ]
            }
        }
    ];

    useEffect(() => {
        const url = "tenant/";
        sendRequest(
            { url },
            {
                message: {
                    error: 'Nije moguće dovući radnje'
                }
            },
            {
                success: (res) => {
                    setTenantList(res);
                }
            }
        );
    }, [sendRequest, reload]);

    return (
        <Box m="20px">
            <Header title="Radnje" subtitle="Moje radnje" />
            <Button type="submit" onClick={() => dispatch(openDrawer('tenant'))} color="secondary" variant="contained">
                Dodaj radnju
            </Button>
            <DataGridWrapper rows={tenantList} noDataTitle="Nema radnji" isLoading={isPending} columns={columns} />
            <CreateTenant sx={{mr: 40}} />
        </Box>
    );

}

export default TenantOverview