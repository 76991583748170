import axios from 'axios';
import UserService from './user.service';

const API_URL = `${process.env.REACT_APP_API_URL}`;

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

const appendSlash = (url) => {
    if (url.slice(-1) !== '/' && !url.includes('?')) return `${url}/`;
    return url;
};

function handleSuccess(toastConfig, response) {
    let successMessage = null;
    if (toastConfig && toastConfig.message.success) {
        successMessage = toastConfig.message.success.slice();
        const placeholderMatches = toastConfig.message.success.match(/{(.*?)\}/g);

        if (placeholderMatches) {
            placeholderMatches.forEach((el) => {
                const param = el.slice(1, -1);
                successMessage = successMessage.replace(el, response.data[param]);
            });
        }
    }
    return {data: response.data, successMessage: successMessage};
}

function handleError(responseError, toastConfig) {
    let errorMessage = null;
    const http_status = responseError.status;
    if (http_status === 401) {
    }
    if (http_status === 408) {
        toastConfig.message.error = "Vaše logovanje je isteklo. Ulogujte se ponovo";
    }
    if (http_status === 403) {
        toastConfig.message.error = "Nemate dozvolu za ovu akciju";
    }
    if (http_status === 409) {
        toastConfig.message.error += " " + responseError?.data?.detail;
    }
    if (toastConfig?.message[http_status]) errorMessage = toastConfig?.message[http_status];
    else errorMessage = toastConfig?.message?.error || responseError.toString();
    responseError.errorMessage = errorMessage;
    throw responseError;
}

const _axios = axios.create();

const configure = () => {
    _axios.interceptors.request.use((config) => {
        if (UserService.isLoggedIn()) {
            config.headers.Authorization = `Bearer ${UserService.getAccessToken()}`;
        }
        return Promise.resolve(config);
    });
};

const ApiService = {
    async fetchBlob(url) {
        //Authorization token should not be sent in the headers when fetching images from public server, so we use regular axios here
        return axios.get(API_URL + url, {
            headers,
            responseType: 'blob'
        });
    },
    async apiRequest(requestConfig, toastConfig) {
        switch (requestConfig.method) {
            case 'GET':
                return await this.get(requestConfig.url, toastConfig);
            case 'POST':
                return await this.post(requestConfig.url, requestConfig.body, toastConfig, requestConfig.headers);
            case 'PATCH':
                return await this.patch(requestConfig.url, requestConfig.body, toastConfig);
            case 'DELETE':
                return await this.delete(requestConfig.url, toastConfig);
            case 'fetchBlob':
                return await this.fetchBlob(requestConfig.url);
            default:
                return await this.get(requestConfig.url, toastConfig);
        }
    },
    async get(api, toastConfig) {
        try {
            let response = await _axios.get(API_URL + api, { headers });
            return handleSuccess(toastConfig, response);
        } catch (responseError) {
            return handleError(responseError.response, toastConfig);
        }
    },
    async post(api, data, toastConfig, customHeaders) {
        try {

            let response = await _axios.post(API_URL + api, data, { ...headers, ...customHeaders });
            return handleSuccess(toastConfig, response);
        } catch (responseError) {
            return handleError(responseError.response, toastConfig);
        }
    },
    async put(api, data, toastConfig) {
        try {
            let response = await _axios.put(API_URL + api, data, { headers });
            return handleSuccess(toastConfig, response);
        } catch (responseError) {
            return handleError(responseError.response, toastConfig);
        }
    },
    async patch(api, data, toastConfig) {
        try {
            let response = await _axios.patch(API_URL + api, data, { headers });
            return handleSuccess(toastConfig, response);
        } catch (responseError) {
            return handleError(responseError.response, toastConfig);
        }
    },
    async delete(api, toastConfig) {
        try {
            let response = await _axios.delete(API_URL + api, { headers });
            return handleSuccess(toastConfig, response);
        } catch (responseError) {
            return handleError(responseError.response, toastConfig);
        }
    }
};

export default ApiService;
export { configure };
