import {useCallback, useContext, useState} from 'react';
import ApiService from '../services/api.service';
import {NotificationContext} from "../context/NotificationContext";
import {useNavigate} from "react-router-dom";

const useHttp = () => {
  const [isPending, setIsPending] = useState(false);
  const apiService = ApiService;
  const {createNotification} = useContext(NotificationContext)
  const navigate = useNavigate()

  const sendRequest = useCallback(async (requestConfig, toastConfig, applyData) => {
    setIsPending(true);
    try {
      const response = await apiService.apiRequest(requestConfig, toastConfig);
      if (applyData?.success) {
        applyData?.success(response.data);
        if (response?.successMessage) {
          createNotification({
            message: response.successMessage,
            status: 'success',
          });
        }
      }
    } catch (err) {
      if (err?.errorMessage) {
        createNotification({
          message: err.errorMessage,
          status: 'error',
        });
      }
      if (err.status === 408) {
        setTimeout(() => navigate('/login'), 2000);
      }
      if (applyData?.error) {
        applyData.error(err);
      }
    }
    setIsPending(false);
  }, []);

  return {
    isPending,
    sendRequest
  };
};

export default useHttp;
