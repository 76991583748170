import {createSlice} from '@reduxjs/toolkit'

export const drawerSlice = createSlice({
    name: 'drawer',
    initialState: {
        tenant: false,
        drawerData: null
    },
    reducers: {
        openDrawer: (state, action) => {
            state[action.payload] = true
        },
        closeDrawer: (state, action) => {
            state[action.payload] = false
            state.drawerData = null
        },
        setDrawerData: (state, action) => {
            state.drawerData = action.payload
        },
    }
})

export const { openDrawer, closeDrawer, setDrawerData } = drawerSlice.actions

export default drawerSlice.reducer

export const tenantOpen = state => state.drawer.tenant


export const drawerData = state => state.drawer.drawerData