import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../theme";
import {useDispatch, useSelector} from "react-redux";
import {mobile, setTenant, tenantsList} from "../reducers/mainContentReducer";

const TenantPicker = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const tenantList = useSelector(tenantsList)
    const isMobile = useSelector(mobile)
    const dispatch = useDispatch()

    return (
        <Box m="20px">
            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="120px"
                gap="20px"
                sx={{
                    "& > div": {gridColumn: !isMobile ? undefined : "span 12"},
                }}
            >
                {tenantList.map((tenant, i) => (
                    <Box
                        key={tenant.id}
                        gridColumn="span 3"
                        gridRow="span 2"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        sx={{cursor: "pointer"}}
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => {dispatch(setTenant(tenant))}}
                    ><Typography
                        color={colors.blueAccent[400]}
                        variant="h5"
                        fontWeight="600"
                    >
                        {tenant.name}
                    </Typography></Box>
                ))}
            </Box>
        </Box>
    );
};

export default TenantPicker;