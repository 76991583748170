import ApiService from "./api.service";

const login = async (formData) => {
  try{
    return await ApiService.post(`auth/access-token`, formData);
  }
  catch (responseError) {
    return responseError.status;
  }

}

const logout = () => {

};

const getAccessToken = () => {
  const _user = JSON.parse(sessionStorage.getItem("_user"));
  return _user?.token

};

const isLoggedIn = () => {
  const _user = JSON.parse(sessionStorage.getItem("_user"));
  return !!_user;
};

const updateAccessToken = async (successCallback) => {
//TODO::edit
};

const getUsername = () => JSON.parse(sessionStorage.getItem("_user")).username;

const getFullName = () =>  JSON.parse(sessionStorage.getItem("_user")).ime_i_prezime;

//const hasRole = (roles) => roles.some((role) => keycloak.hasRealmRole(role));

const UserService = {
  login,
  logout,
  isLoggedIn,
  getAccessToken,
  updateAccessToken,
  getUsername,
  getFullName,
  //hasRole
};

export default UserService;
