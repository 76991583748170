import {configureStore} from '@reduxjs/toolkit'
import drawerReducer from "../reducers/drawerReducer";
import mainContentReducer from "../reducers/mainContentReducer";

const store = configureStore({
    reducer: {
        drawer: drawerReducer,
        mainContent: mainContentReducer

    }
})
export default store