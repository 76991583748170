import {Box, IconButton, InputBase, useTheme} from "@mui/material";
import {useContext, useEffect, useMemo, useState} from "react";
import {ColorModeContext, tokens} from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Autocomplete from '../../forms/Autocomplete';
import useHttp from "../../hooks/useHttp";
import {Formik} from "formik";
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {
    reloadMain,
    setTenant,
    setTenantList,
    tenantData,
    tenantsList,
    toggleSidebar
} from "../../reducers/mainContentReducer";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PopupMenu from '@mui/material/Menu';
import PopupMenuItem from '@mui/material/MenuItem';
import {useNavigate} from "react-router-dom";

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const {sendRequest} = useHttp();
    const dispatch = useDispatch()
    const reload = useSelector(reloadMain)
    const activeTenant = useSelector(tenantData)
    const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
    const profileMenuOpen = Boolean(profileMenuAnchor);

    const navigate = useNavigate();

    const tenantList = useSelector(tenantsList)

    useEffect(() => {
        const url = "tenant/";
        sendRequest(
            {url},
            {
                message: {
                    error: 'Nije moguće dovući radnje!'
                }
            },
            {
                success: (res) => {
                    dispatch(setTenantList(res));
                }
            }
        );
    }, [sendRequest]);

    const INITIAL_FORM_STATE = useMemo(() => {
        return {
            tenant: activeTenant?.id ? activeTenant : null
        };
    }, [activeTenant]);

    const FORM_VALIDATION = Yup.object().shape({
        tenant: Yup.object().nullable(),
    });

    const handleSelectTenant = (tenant) => {
        if (tenant) {
            dispatch(setTenant(tenant))
        } else {
            dispatch(setTenant(null))
        }
    };
    const handleLogOut = () => {
        sessionStorage.setItem("_user", null);
        navigate('/logout')
    }

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            <Box display="flex"
                 sx={{flex: 1}}

            >
                <IconButton sx={{marginRight: "10px"}} onClick={() => {
                    dispatch(toggleSidebar())
                }}>
                    <MenuOutlinedIcon/>
                </IconButton>
                <Box
                    display="flex"
                    backgroundColor={colors.primary[400]}
                    borderRadius="3px"
                    sx={{flex: 0.5}}
                >
                    <Formik enableReinitialize={true}
                            initialValues={{
                                ...INITIAL_FORM_STATE
                            }}
                            validationSchema={FORM_VALIDATION}>
                        <Autocomplete
                            name="tenant"
                            label="Izaberite radnju..."
                            options={tenantList}
                            value={activeTenant}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.name}
                                </Box>
                            )}
                            onChange={(tenant) => {
                                handleSelectTenant(tenant);
                            }}
                            sx={{flex: 1}}
                            renderOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        />
                    </Formik>
                </Box>
            </Box>
            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon/>
                    ) : (
                        <LightModeOutlinedIcon/>
                    )}
                </IconButton>
                <IconButton>
                    <NotificationsOutlinedIcon/>
                </IconButton>
                <IconButton>
                    <SettingsOutlinedIcon/>
                </IconButton>
                <IconButton onClick={(event) => {
                    setProfileMenuAnchor(event.currentTarget)
                }}>
                    <PersonOutlinedIcon/>
                </IconButton>
                <PopupMenu
                    id="profile-menu"
                    anchorEl={profileMenuAnchor}
                    open={profileMenuOpen}
                    onClose={() => setProfileMenuAnchor(null)}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <PopupMenuItem>Izmena šifre</PopupMenuItem>
                    <PopupMenuItem onClick={handleLogOut}>Izlazak iz programa</PopupMenuItem>
                </PopupMenu>
            </Box>
        </Box>
    );
};

export default Topbar;