import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {reloadMain, tenantData} from "../reducers/mainContentReducer";
import useHttp from "../hooks/useHttp";
import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../theme";
import PeriodPicker from "./PeriodPicker";
import DataLoader from "./DataLoader";
import {PieChart} from "@mui/x-charts";
import CachedIcon from '@mui/icons-material/Cached';

const StoreDistributionPieMui = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const activeTenant = useSelector(tenantData)
    const {sendRequest} = useHttp();
    const dispatch = useDispatch();
    const [periodFrom, setPeriodFrom] = useState(null);
    const [periodTo, setPeriodTo] = useState(null);
    const [activePeriod, setActivePeriod] = useState('ALL');
    const [isLoading, setIsLoading] = useState(true);
    const [pieData, setPieData] = useState([]);
    const [total, setTotal] = useState(0);
    const reload = useSelector(reloadMain)

    const getArcLabel = (params) => {
        const percent = params.value / total;
        return `${(percent * 100).toFixed(0)}%`;
    };

    useEffect(() => {
        if (activeTenant?.id) {
            setIsLoading(true);
            let url = "tenant/transactions/" + activeTenant?.id + "/store_distribution"
            if (periodFrom && periodTo) {
                const periodFromStr = new Date(periodFrom).toISOString().slice(0, 10)
                const periodToStr = new Date(periodTo).toISOString().slice(0, 10)
                url += `?date_from=${periodFromStr}&date_to=${periodToStr}`
            }
            sendRequest(
                {url},
                {
                    message: {
                        error: 'Nije moguće dovući distribuciju!'
                    }
                },
                {
                    success: (res) => {
                        setTotal(res.map((item) => item.total_amount).reduce((a, b) => a + b, 0))
                        setPieData(res.map((item, index) => ({
                            id: index,
                            value: item.total_amount,
                            label: item.store_name
                        })))
                        setIsLoading(false);
                    },
                    error: () => {
                        setIsLoading(false)
                    }

                }
            );
        }
    }, [activeTenant, sendRequest, periodFrom, periodTo, reload]);
    return (
            <DataLoader isLoading={isLoading}>
            { total !== 0 ? (
                <Box><PeriodPicker activePeriod={activePeriod} setActivePeriod={setActivePeriod}
                                   setPeriodFrom={setPeriodFrom} setPeriodTo={setPeriodTo}/>
                    <PieChart
                        slotProps={{
                            legend: {
                                hidden: true,
                                direction: 'row',
                                position: {vertical: 'bottom', horizontal: 'middle'}
                            },
                        }}
                        series={[
                            {
                                arcLabel: getArcLabel,
                                arcLabelMinAngle: 20,
                                data: pieData,
                            },
                        ]}
                        height={300}
                    />
                </Box>) : <Typography
                variant="h4"
                color={colors.greenAccent[500]}
                sx={{ display: "inline-flex", alignItems: "center", mt: "15px" }}
            >
                Nema podataka
                <CachedIcon
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    onClick={() => {
                        setActivePeriod('ALL')
                        setPeriodFrom(null);
                        setPeriodTo(null);
                    }}
                    fontSize="large"
                />
            </Typography>}
            </DataLoader>
    );
}

export default StoreDistributionPieMui;