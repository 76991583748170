import {Box, Stack} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import ProgressBar from "../../layouts/progress_bar";
import NoData from "../../layouts/no_data";
import './index.css'

const DataGridWrapper = ({isLoading, rows, columns, onCellClick, noDataTitle, getRowId, sx, ...otherProps}) => {
    return <Box
        m="40px 0 0 0"
        height="55vh"
        sx={{
            '@media print': {
                '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 1)' },
            },
            ".MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important"
            },
            ".MuiButtonBase-root": {
                color: "#fff !important"
            },
            ...sx
        }}

    >
        {isLoading && <ProgressBar open={true}/>}
        {!isLoading && <DataGrid
            disableHorizontalScrollbar
            onCellClick={onCellClick}
            csvOptions={{
                utf8WithBom: true,
            }}
            slots={{
                toolbar: GridToolbar,
                noRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        <NoData title={noDataTitle}/>
                    </Stack>
                ),
                noResultsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        <NoData title="Nema rezultata"/>
                    </Stack>
                )
            }}
            loading={isLoading}
            localeText={{
                MuiTablePagination: {
                    labelDisplayedRows: ({from, to, count}) =>
                        `${from} - ${to} od ${count}`,
                    labelRowsPerPage: 'Redova po strani'
                },
                toolbarFilters: 'Filteri',
                toolbarColumns: 'Kolone',
                toolbarQuickFilterPlaceholder: 'Pretraga',
                toolbarDensity: 'Veličina',
                toolbarDensityLabel: 'Veličina',
                toolbarDensityCompact: 'Kompaktno',
                toolbarDensityStandard: 'Srednje',
                toolbarDensityComfortable: 'Veliko',
                filterOperatorContains: 'sadrži',
                filterOperatorEquals: 'jednako',
                filterOperatorStartsWith: 'počinje sa',
                filterOperatorEndsWith: 'završava se sa',
                filterOperatorIs: 'je',
                filterOperatorNot: 'nije',
                filterOperatorIsEmpty: 'prazno',
                filterOperatorIsNotEmpty: 'nije prazno',
                filterOperatorIsAnyOf: 'bilo koji od',
                filterPanelAddFilter: 'Dodaj filter',
                filterPanelRemoveAll: 'Ukloni sve',
                filterPanelDeleteIconLabel: 'Obriši',
                filterPanelLogicOperator: 'Logički operator',
                filterPanelOperator: 'Operator',
                filterPanelOperatorAnd: 'I',
                filterPanelOperatorOr: 'Ili',
                filterPanelColumns: 'Kolone',
                filterPanelInputLabel: 'Vrednost',
                filterPanelInputPlaceholder: 'Vrednost filtera',
                columnsPanelTextFieldLabel: 'Nađi kolonu',
                columnsPanelTextFieldPlaceholder: 'Naslov kolone',
                columnsPanelDragIconLabel: 'Reorganizuj kolone',
                columnsPanelShowAllButton: 'Prikaži sve',
                columnsPanelHideAllButton: 'Sakrij sve'
            }}
            disableRowSelectionOnClick
            slotProps={{
                toolbar: {
                    printOptions:{
                        hideFooter: true,
                        hideToolbar: true,
                        pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); }',
                    },
                    showQuickFilter: true,
                    quickFilterProps: {debounceMs: 500},
                },
            }}
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {pageSize: 100, page: 0},
                },
            }}
            getRowId={getRowId}
            {...otherProps}
        />}
    </Box>
}

export default DataGridWrapper