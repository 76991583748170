import {useMemo} from 'react';
import {useField} from 'formik';
import {TextField} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import {tokens} from "../../theme";

const TextfieldWrapper = ({outOfFormikContext, name, disabled, ...otherProps }) => {
    const [field, meta] = useField(name);
    const passField = outOfFormikContext ? {} : { ...field }; // If we are using the component and we dont need field - i.e. in Autocomplete

    const theme = useTheme(); // WebkitTextFillColor can not direct access to the theme colors in sx
    const colors = tokens(theme.palette.mode);

    const configTextfield = {
        ...passField,
        disabled,
        size: 'small',
        fullWidth: true,
        variant: 'standard',
        ...otherProps
    };

    if (meta && meta.touched && meta.error) {
        configTextfield.error = true;
        configTextfield.helperText = meta.error;
    }

    const textField = useMemo(
        () => (
            <TextField
                {...configTextfield}
                className="InputTextField"
                InputLabelProps={{
                    style: { color: `${colors.primary[100]}` },
                    ...configTextfield.InputLabelProps
                }}
                InputProps={{
                    autoComplete: "off",
                    ...configTextfield.InputProps
                }}
            />
        ),
        [configTextfield, colors.primary]
    );

    return <>{textField}</>;
};

export default TextfieldWrapper;
