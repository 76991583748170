import React, {useEffect, useRef, useState} from 'react';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import QrScanner from "qr-scanner";
import IconButton from "@mui/material/IconButton";

const QRScannerDialog = ({open, onClose}) => {
    const qrScannerRef = useRef(null);
    const [hasFlash, setHasFlash] = useState(false);
    const [flashOn, setFlashOn] = useState(false);

    const handleCloseDialog = (data) => {
        qrScannerRef.current.turnFlashOff();
        qrScannerRef.current.destroy();
        onClose(data);
    }
    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <DialogTitle>Dodaj račun</DialogTitle>
            <DialogContent>
                { hasFlash && <IconButton
                    onClick={() => {
                        if (flashOn) {
                            qrScannerRef.current.turnFlashOff();
                        } else {
                            qrScannerRef.current.turnFlashOn();
                        }
                        setFlashOn(!flashOn);
                    }}

                >
                    {flashOn ? <FlashOffIcon /> : <FlashOnIcon /> }
                </IconButton>
                }
                <video style={{width: '100%'}} id="reader" ref={(node) => {
                    if (!open && qrScannerRef.current) {
                        qrScannerRef.current = null;
                    }
                    if (open && !qrScannerRef.current) {
                        const qrScanner = new QrScanner(
                            node,
                            result => {
                                qrScanner.turnFlashOff()
                                qrScanner.destroy();
                                onClose(result);
                            },
                            {
                                preferredCamera: 'environment',
                                highlightScanRegion: true,
                                maxScansPerSecond: 10,
                            },
                        );
                        qrScanner.start();
                        qrScanner.hasFlash().then(hasFlash => {
                            if (hasFlash) {
                                setHasFlash(true)
                                qrScanner.turnFlashOn();
                            }
                        });
                        qrScannerRef.current = qrScanner;
                    }
                }}></video>
            </DialogContent>
        </Dialog>
    );
}

export default QRScannerDialog;