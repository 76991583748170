import {Box, DialogContent} from '@mui/material';

export default function DialogContentBootstraped({ children, noDividers }) {
  return (
    <DialogContent
      dividers={!noDividers}
      sx={{ borderColor: 'background.seperateBordercolor', pl: 5, pr: 5 }}
    >
      <Box sx={{ pt: 2, pb: 2 }}>{children}</Box>
    </DialogContent>
  );
}
