import {Box, Tooltip, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import QRCodeScanner from "../../components/QRCodeScanner";
import {useSelector} from "react-redux";
import {reloadMain, tenantData} from "../../reducers/mainContentReducer";
import {useEffect, useState} from "react";
import useHttp from "../../hooks/useHttp";
import moment from "moment";
import DataLoader from "../../components/DataLoader";
import useMediaQuery from "@mui/material/useMediaQuery";
import TenantPicker from "../../components/TenantPicker";
import MonthlyTotalsWithTotalAmount from "../../components/MonthyTotalsWithTotalAmount";
import MonthyIncrease from "../../components/MonthyIncrease";
import StoreDistributionPieMui from "../../components/StoreDistributionPieMui";
import LatestTranstactions from "../../components/LatestTranstactions";

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const activeTenant = useSelector(tenantData)
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const {sendRequest} = useHttp();
    const [latestTransactions, setLatestTransactions] = useState([])
    const [latestTransactionsLoading, setLatestTransactionsLoading] = useState(false)
    const reload = useSelector(reloadMain)

    useEffect(() => {
        if (activeTenant?.id) {
            setLatestTransactionsLoading(true)
            const url = "tenant/transactions/" + activeTenant?.id;
            sendRequest(
                {url},
                {
                    message: {
                        error: 'Nije moguće dovući transakcije!'
                    }
                },
                {
                    success: (res) => {
                        setLatestTransactions(res);
                        setLatestTransactionsLoading(false)
                    },
                    error: () => {
                        setLatestTransactionsLoading(false)
                    }

                }
            );
        }
    }, [sendRequest, activeTenant, reload]);

    return !activeTenant ? <TenantPicker/> : (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={activeTenant?.name} subtitle=""/> {!isNonMediumScreens && <QRCodeScanner/>}
            </Box>
            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& > div": {gridColumn: isNonMediumScreens ? undefined : "span 12"},
                }}
            >
                {/* ROW 1 */}
                <Box
                    gridColumn="span 3"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                ><StoreDistributionPieMui/>
                </Box>
                <Box
                    gridColumn="span 3"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <MonthyIncrease />
                </Box>
                <Box
                    gridColumn="span 3"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                </Box>
                <Box
                    gridColumn="span 3"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                </Box>

                {/* ROW 2 */}
                <Box
                    gridColumn="span 8"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                >
                        <MonthlyTotalsWithTotalAmount />
                </Box>
                <Box
                    gridColumn="span 4"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    overflow="auto"
                ><LatestTranstactions />
                </Box>

                {/* ROW 3 */}
                <Box
                    gridColumn="span 4"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    p="30px"
                >
                    <Typography variant="h5" fontWeight="600">
                        Campaign
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mt="25px"
                    >
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[500]}
                            sx={{mt: "15px"}}
                        >
                        </Typography>
                        <Typography>Includes extra misc expenditures and costs</Typography>
                    </Box>
                </Box>
                <Box
                    gridColumn="span 4"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                >
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;