import jwtDecode from "jwt-decode";


const useAuth = () => {
    const token = JSON.parse(sessionStorage.getItem("_user"))?.token

    if (token) {
        const decoded = jwtDecode(token)
        const { username, name, id } = decoded
        return { username,  name, id }
    }

    return { username: ''}
}
export default useAuth