import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const DataLoader = ({ isLoading, children }) => {
    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                <CircularProgress color="inherit" />
            </Box>
        );
    }

    return <>{children}</>
};

export default DataLoader;