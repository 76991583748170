import {Navigate, Outlet} from "react-router-dom";

import React from "react";
import useAuth from "../hooks/useAuth";

const AuthenticatedRoute = () => {
    const { username, role } = useAuth()

    if (username === '')
        return <Navigate to="/login"/>;
    else
        return <Outlet />
};

export default AuthenticatedRoute;