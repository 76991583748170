import React, { useState } from 'react';
import { Button } from '@mui/material';
import QRScannerDialog from './QRScannerDialog';
import {useDispatch, useSelector} from "react-redux";
import {hideBackdrop, showBackdrop, tenantData} from "../reducers/mainContentReducer";
import useHttp from "../hooks/useHttp";

const QRCodeScanner = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [qrCodeData, setQRCodeData] = useState('');
    const activeTenant = useSelector(tenantData)
    const { sendRequest} = useHttp();
    const dispatch = useDispatch()


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (data) => {
        setOpenDialog(false);
        const decodedUrl = data?.data
        if (decodedUrl && typeof decodedUrl === 'string') {
            dispatch(showBackdrop("Dodavanje računa..."))
            const url = "receipt/";
            const urlParams = new URLSearchParams(new URL(decodedUrl).search);
            const vlValue = urlParams.get("vl")
            const body = {
                "vl": vlValue,
                "tenant_id": activeTenant?.id
            }
            sendRequest(
                {url, method: 'POST', body},
                {
                    message: {
                        success: 'Račun uspešno dodat!',
                        error: 'Nije moguće dodati račun!'
                    }
                },
                {
                    success: (res) => {
                        dispatch(hideBackdrop(null))
                    },
                    error: () => {
                        dispatch(hideBackdrop(null))
                    }
                }
            );
        }
    };

    return (
        <div>
            <Button variant="contained" onClick={handleOpenDialog}>
                Dodaj račun
            </Button>
            <QRScannerDialog open={openDialog} onClose={handleCloseDialog} />
        </div>
    );
};

export default QRCodeScanner;