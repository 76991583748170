import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ConfirmDialogContextProvider from "./context/ConfirmDialogContext";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Login from "./scenes/login";
import {configure} from "./services/api.service";
import {Provider} from "react-redux";
import store from './store'

const root = ReactDOM.createRoot(document.getElementById('root'));
(async () => {
    await configure();
    root.render(
        <Provider store={store}>
            <ConfirmDialogContextProvider>
                <Router>
                    <Routes>
                        <Route element={<AuthenticatedRoute />}>
                            <Route path="*" element={<App/>}/>
                        </Route>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/logout" element={<Login/>}/>
                    </Routes>
                </Router>
            </ConfirmDialogContextProvider>

        </Provider>
    );
})();
