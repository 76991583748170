import {Button, Dialog, DialogActions, Grid, Stack, Typography, useTheme} from '@mui/material';
import {useContext} from 'react';
import DialogContentBootstraped from "../layouts/dialog_content_bootstraped";
import DialogHeaderTitle from "../layouts/dialog_header_title";
import {ConfirmDialogContext} from "../context/ConfirmDialogContext";
import {tokens} from "../theme";

const ConfirmDialog = () => {
    const {
        title,
        message,
        onSubmit,
        close,
        confirmText = 'Potvrdi',
        cancelText = 'Poništi',
        onCancel
    } = useContext(ConfirmDialogContext);
    // destructure the store data and functions
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleOnClickCancel = () => {
        onCancel();
        close();
    };

    return (
        // if the onSubmit is undefined the dialog will be closed.
        // close() function sets the onSubmit to undefined,
        // so it will close the dialog, if we pass it to the onClose attribute.
        <Dialog open={Boolean(onSubmit)} onClose={close} maxWidth={'xs'} fullWidth>
            <DialogHeaderTitle><Typography variant="h3" color="secondary">{title}</Typography></DialogHeaderTitle>

            <DialogContentBootstraped noDividers={true}>
                {/* Dialog content: */}

                <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12}>
                        <Stack justifyContent="center" alignItems="center">
                            <Typography>{message}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContentBootstraped>

            <DialogActions>
                <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <Button
                            fullWidth
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                if (onSubmit) {
                                    onSubmit();
                                }
                                close();
                            }}
                        >
                            {confirmText}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            fullWidth
                            color="warning"
                            variant="contained"
                            onClick={() => {
                                handleOnClickCancel();
                            }}
                        >
                            {cancelText}
                        </Button>
                    </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
