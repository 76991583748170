import {Box, DialogTitle, Stack} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function DialogHeaderTitle({ children, onClose }) {
  return (
    <DialogTitle>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Box sx={{ color: 'text.formColorTitle', p: 1 }}>{children}</Box>
      </Stack>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
