import './no_data.css';
import Warning from '@mui/icons-material/Warning';
import {Box} from '@mui/material';

export default function NoData({ title = 'Nema podataka' }) {
    return (
        <Box className="NoData" sx={{ pt: 8, pb: 8 }}>
            {title}
            <Box sx={{ mt: 1, '& svg': { color: 'warning.light' } }}>
                <Warning />
            </Box>
        </Box>
    );
}
