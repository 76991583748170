import React, {useEffect, useState} from 'react';
import {Box, Tooltip, Typography, useTheme} from "@mui/material";
import DataLoader from "./DataLoader";
import moment from "moment/moment";
import {tokens} from "../theme";
import {useSelector} from "react-redux";
import {reloadMain, tenantData} from "../reducers/mainContentReducer";
import useMediaQuery from "@mui/material/useMediaQuery";
import useHttp from "../hooks/useHttp";

const LatestTranstactions = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const activeTenant = useSelector(tenantData)
    const {sendRequest} = useHttp();
    const [latestTransactions, setLatestTransactions] = useState([])
    const [latestTransactionsLoading, setLatestTransactionsLoading] = useState(false)
    const reload = useSelector(reloadMain)

    useEffect(() => {
        if (activeTenant?.id) {
            setLatestTransactionsLoading(true)
            const url = "tenant/transactions/" + activeTenant?.id;
            sendRequest(
                {url},
                {
                    message: {
                        error: 'Nije moguće dovući transakcije!'
                    }
                },
                {
                    success: (res) => {
                        setLatestTransactions(res);
                        setLatestTransactionsLoading(false)
                    },
                    error: () => {
                        setLatestTransactionsLoading(false)
                    }

                }
            );
        }
    }, [sendRequest, activeTenant, reload]);
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                colors={colors.grey[100]}
                p="15px"
            >
                <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                    Poslednje kupovine
                </Typography>
            </Box>
            <DataLoader isLoading={latestTransactionsLoading}>
            {latestTransactions.map((transaction, i) => (
                <Box
                    key={transaction.id}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    p="15px"
                >
                    <Box>
                        <Typography
                            color={colors.blueAccent[400]}
                            variant="h5"
                            fontWeight="600"
                        >
                            {transaction.txId}
                        </Typography>
                        <Tooltip title={transaction.store.company.display_name}>
                            <Typography color={colors.grey[100]}>
                                {transaction.store.display_name}
                            </Typography>
                        </Tooltip>
                    </Box>
                    <Box
                        color={colors.grey[100]}>{moment(new Date(transaction.purchase_date)).format('DD.MM.YYYY  H:mm:ss')}</Box>
                    <Box
                        backgroundColor={colors.greenAccent[500]}
                        p="5px 10px"
                        borderRadius="4px"
                    >
                        RSD {transaction.total_amount}
                    </Box>
                </Box>
            ))}
                {latestTransactions.length === 0 && <Box><Typography
                    variant="h4"
                    color={colors.greenAccent[500]}
                    sx={{mt: "15px"}}>Nema podataka</Typography></Box>}
            </DataLoader>
        </>
    );
};

export default LatestTranstactions;