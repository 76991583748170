import React from "react";
import {Box, LinearProgress} from "@mui/material";

export default function ProgressBar() {

    return (
        <Box sx={{ width: '99%' }}>
            <LinearProgress color="secondary" />
        </Box>
    );
}