import React, {useEffect, useState} from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../theme";
import {useSelector} from "react-redux";
import {reloadMain, tenantData} from "../reducers/mainContentReducer";
import useHttp from "../hooks/useHttp";
import DataLoader from "./DataLoader";

const MonthyIncrease = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const activeTenant = useSelector(tenantData)
    const {sendRequest} = useHttp();
    const [monthlyData, setMonthlyData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const reload = useSelector(reloadMain)

    useEffect(() => {
        if (activeTenant?.id) {
            setIsLoading(true);
            let url = "tenant/transactions/" + activeTenant?.id + "/monthly_changes"
            sendRequest({url}, {
                message: {
                    error: 'Nije moguće dovući potrošnju po mesecima!'
                }
            }, {
                success: (res) => {
                    setMonthlyData(res)
                    setIsLoading(false)
                }, error: () => {
                    setIsLoading(false)
                }

            });
        }
    }, [activeTenant, sendRequest, reload]);

    return (<div>
            <DataLoader isLoading={isLoading}>
            {(!isLoading && monthlyData) && (monthlyData?.this_month === 0) ? <Typography
                variant="h4"
                color={colors.greenAccent[500]}
                sx={{mt: "15px"}}
            >Nema podataka</Typography> : <Box>
                <Typography variant={"h4"}>Tekući mesec: <span style={{color: colors.greenAccent[500]}}>RSD {monthlyData?.this_month}</span> </Typography>



                <Typography variant={"h4"}>U odnosu na prethodni mesec:</Typography>
                <Typography
                    variant="h4"
                    color={colors.greenAccent[500]}
                    sx={{display: "inline", mt: "15px"}}
                >
                    {monthlyData?.last_month_diff !== 0 ?

                        monthlyData?.last_month_diff < 0 ? <Box display="flex" alignItems="center">
                            <KeyboardDoubleArrowDownIcon color="success" style={{ fontSize: 100 }}/>
                            <Typography variant="h4">
                                {monthlyData?.last_month_diff.toFixed(2)}%
                            </Typography>
                        </Box> : <Box display="flex" alignItems="center">
                            <KeyboardDoubleArrowUpIcon color="error" style={{ fontSize: 100 }}/>
                            <Typography variant="h4">
                                {monthlyData?.last_month_diff.toFixed(2)}%
                            </Typography>
                        </Box> : "Nema podataka"}
                </Typography>
                <Typography variant={"h4"}>Isti mesec prethodne godine:</Typography>
                <Typography
                    variant="h4"
                    color={colors.greenAccent[500]}
                    sx={{display: "inline", mt: "15px"}}
                >
                {monthlyData?.same_month_last_year_diff !== 0 ?

                    monthlyData?.same_month_last_year_diff < 0 ? <Box display="flex" alignItems="center">
                        <KeyboardDoubleArrowDownIcon color="success" style={{ fontSize: 100 }}/>
                        <Typography variant="h4">
                            {monthlyData?.same_month_last_year_diff.toFixed(2)}%
                        </Typography>
                    </Box> : <Box display="flex" alignItems="center">
                        <KeyboardDoubleArrowUpIcon color="error" fontSize="x-large"/>
                        <Typography variant="h4">
                            {monthlyData?.same_month_last_year_diff.toFixed(2)}%
                        </Typography>
                    </Box> : "Nema podataka"}</Typography></Box>}
            </DataLoader>
        </div>);
};

export default MonthyIncrease;