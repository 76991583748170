import {Box, Button, TextField, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import {Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserService from "../../services/user.service";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import jwtDecode from "jwt-decode";

const Login = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("");
    const handleFormSubmit = async (values) => {
        setErrorMsg(null);
        const formData = new FormData()
        for (const key in values) {
            formData.append(key, values[key]);
        }
        const response = await UserService.login(formData);
        if (response.data) {
            let _user_data = jwtDecode(response.data?.access_token)
            _user_data.token = response.data?.access_token
            sessionStorage.setItem("_user", JSON.stringify(_user_data));

            navigate('/');
        }
        else if (response === 401) {
            setErrorMsg('Pogrešni podaci. Pokušajte ponovo!!!')
        } else {
            setErrorMsg('Došlo je do greške. Pokušajte ponovo!!!')
        }
    };
    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
        >

            {({
                  values, errors, touched, handleBlur, handleChange, handleSubmit,
              }) => (<form style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmit}>
                <Box width="20%" mt="100px" mb="30px" gap="30px" display="flex" alignSelf="center" alignItems="center"
                     sx={{flexDirection: "column"}}>
                    <Box sx={{color: "red"}}>{errorMsg}</Box>
                    <img
                        alt="profile-user"
                        width="200px"
                        src={`../../racunko_logo.png`}
                        style={{cursor: "pointer"}}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        type="text"
                        label="Korisničko ime"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.username}
                        name="username"
                        error={!!touched.username && !!errors.username}
                        helperText={touched.username && errors.username}
                    />
                    <TextField
                        placeholder="Šifra"
                        fullWidth
                        variant="standard"
                        type="password"
                        label="Šifra"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.opis}
                        name="password"
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                    />
                    <Box display="flex" justifyContent="center" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Login
                        </Button>
                    </Box>
                </Box>
            </form>)}
        </Formik>
    );
};
const checkoutSchema = yup.object().shape({
    username: yup.string().required("Obavezno polje"),
    password: yup.string().required("Obavezno polje")
});
const initialValues = {
    username: "", password: ""
};

export default Login;
