import React, {createContext, useState} from 'react';

export const ConfirmDialogContext = createContext();

const ConfirmDialogContextProvider = (props) => {
  const [params, setParams] = useState({
    title: '',
    message: '',
    onSubmit: undefined,
    onCancel: undefined,
    confirmText: 'Confirm',
    cancelText: 'Cancel'
  });

  const confirmDialog = (obj) =>
    setParams({
      ...params,
      title: obj.title,
      message: obj.message,
      onSubmit: obj.onSubmit,
      onCancel: obj.onCancel,
      confirmText: obj.confirmText,
      cancelText: obj.cancelText
    });

  const close = () => setParams({ ...params, onSubmit: undefined });

  const onCancel = () => {
    if (params?.onCancel) {
      params.onCancel();
    }
  };

  return (
    <ConfirmDialogContext.Provider value={{ ...params, confirmDialog, close, onCancel }}>
      {props.children}
    </ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogContextProvider;
