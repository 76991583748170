import {useEffect, useState} from "react";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import {backdropData, setMobile, setSidebarVisible} from "./reducers/mainContentReducer";
import {Backdrop, Box, CircularProgress, CssBaseline, ThemeProvider} from "@mui/material";
import {ColorModeContext, useMode} from "./theme";
import {NotificationProvider} from "./context/NotificationContext";
import ConfirmDialog from "./components/ConfirmDialog";
import {Route, Routes} from "react-router-dom";
import Dashboard from "./scenes/dashboard";
import {useDispatch, useSelector} from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import TenantOverview from "./scenes/tenants";

function App() {
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const backdrop = useSelector(backdropData)
    const dispatch = useDispatch()
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

    useEffect(() => {
        dispatch(setSidebarVisible(isNonMediumScreens));
        dispatch(setMobile(!isNonMediumScreens))
    }, [isNonMediumScreens, dispatch]);


    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <NotificationProvider>
                    <ConfirmDialog/>
                    <div className="app">
                        <Sidebar isSidebar={isSidebar}/>
                        <main className="content">
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={backdrop.visible}
                            >
                                <CircularProgress color="inherit" />
                                <Box ml="10px">{backdrop.text}</Box>
                            </Backdrop>
                            <Topbar setIsSidebar={setIsSidebar}/>
                            <Routes>
                                <Route path="*" element={<Dashboard />} />
                                <Route path="/radnje" element={<TenantOverview />} />
                            </Routes>
                        </main>
                    </div>
                </NotificationProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
);
}

export default App;