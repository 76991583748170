import React, {useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Tooltip,
    useTheme
} from "@mui/material";
import {tokens} from "../theme";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from "moment";

const PeriodPicker = ({activePeriod, setActivePeriod, setPeriodFrom, setPeriodTo, hidden}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [rangePickerOpen, setRangePickerOpen] = useState(false);
    const [value, onChange] = useState([moment(), moment()]);
    const buttonStyles = {
        maxWidth: '50px',
        maxHeight: '50px',
        minWidth: '35px',
        minHeight: '35px',
        '--active-bg-color': colors.greenAccent[500]
    };
    const handleDateChange = (index) => (date) => {
        onChange((prevValue) => {
            const newValue = [...prevValue];
            newValue[index] = date;
            return newValue;
        });
    };
    const handleButtonClick = (activeButton) => {
        setActivePeriod(activeButton);
        let periodFrom;
        let periodTo = new Date(); // current date
        switch (activeButton) {
            case '1M':
                periodFrom = new Date();
                periodFrom.setMonth(periodTo.getMonth() - 1);
                break;
            case '6M':
                periodFrom = new Date();
                periodFrom.setMonth(periodTo.getMonth() - 6);
                break;
            case 'YTD':
                periodFrom = new Date(periodTo.getFullYear(), 0, 1); // start of the current year
                break;
            case '1Y':
                periodFrom = new Date();
                periodFrom.setFullYear(periodTo.getFullYear() - 1);
                break;
            case 'ALL':
                periodFrom = null; // or set to the earliest possible date based on your application
                break;
            case 'CUSTOM':
                setRangePickerOpen(true);
                return;
            default:
                periodFrom = periodTo; // default to current date
        }

        setPeriodFrom(periodFrom);
        setPeriodTo(periodTo);
    };
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            gap="5px"
            maxWidth="385px"
            paddingTop="10px"
            className="toolbar"
        >
            {!hidden?.includes("1M") && <Tooltip title={"1 mesec unazad"}>
                <Button onClick={() => handleButtonClick('1M')} className={activePeriod === '1M' ? 'active' : undefined}
                        style={buttonStyles} color="inherit" variant="outlined">1M</Button>
            </Tooltip>}
            {!hidden?.includes("6M") && <Tooltip title={"6 meseci unazad"}>
                <Button onClick={() => handleButtonClick('6M')} className={activePeriod === '6M' ? 'active' : undefined}
                        style={buttonStyles} color="inherit" variant="outlined">6M</Button>
            </Tooltip>}
            {!hidden?.includes("YTD") && <Tooltip title={"Tekuća godina"}>
                <Button onClick={() => handleButtonClick('YTD')}
                        className={activePeriod === 'YTD' ? 'active' : undefined} style={buttonStyles} color="inherit"
                        variant="outlined">TG</Button>
            </Tooltip>}
            {!hidden?.includes("1Y") && <Tooltip title={"Godinu dana unazad"}>
                <Button onClick={() => handleButtonClick('1Y')} className={activePeriod === '1Y' ? 'active' : undefined}
                        style={buttonStyles} color="inherit" variant="outlined">1G</Button>
            </Tooltip>}
            {!hidden?.includes("ALL") && <Tooltip title={"Sve"}>
                <Button onClick={() => handleButtonClick('ALL')}
                        className={activePeriod === 'ALL' ? 'active' : undefined} style={buttonStyles} color="inherit"
                        variant="outlined">SVE</Button>
            </Tooltip>}
            {!hidden?.includes("CUSTOM") && <Tooltip title={"Izaberi"}>
                <Button onClick={() => {
                    handleButtonClick('CUSTOM')
                }}
                        className={activePeriod === 'CUSTOM' ? 'active' : undefined} style={buttonStyles}
                        color="inherit"
                        variant="outlined">Izaberi</Button>
            </Tooltip>}
            <Dialog open={rangePickerOpen} onClose={() => setRangePickerOpen(false)}>
                <DialogTitle>Izaberi period</DialogTitle>
                <DialogContent sx={{width: "300px", height: "170px"}}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>

                        <DatePicker onChange={handleDateChange(0)}
                                    format="DD.MM.YYYY"
                                    label="Period Od"
                                    value={value[0]}
                                    slotProps={{
                                        textField: {
                                            sx: {
                                                marginTop: "20px",
                                                svg: {color: colors.grey[100]},
                                                input: {color: colors.grey[100]},
                                                '& .MuiOutlinedInput-root': {
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#fff',
                                                        // Change this to the color you want
                                                    },
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#fff'
                                                }
                                            }
                                        }
                                    }}/>
                        <DatePicker
                            onChange={handleDateChange(1)}
                            label="Period Do"
                            value={value[1]}
                            format="DD.MM.YYYY"
                            slotProps={{
                                textField: {
                                    sx: {
                                        marginTop: "20px",
                                        svg: {color: colors.grey[100]},
                                        input: {color: colors.grey[100]},
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#fff',
                                                // Change this to the color you want
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#fff'
                                        }
                                    }
                                }
                            }}/>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={() => setRangePickerOpen(false)}>Otkaži</Button>
                    <Button color="inherit" onClick={() => {
                        setPeriodFrom(value[0]);
                        setPeriodTo(value[1])
                    }} type="submit">Izaberi</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}

export default PeriodPicker;