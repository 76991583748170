import {Box, Button, DialogTitle, Drawer, useTheme} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import {tokens} from "../theme";
import Textfield from "../forms/Textfield";
import useHttp from "../hooks/useHttp";
import {useDispatch, useSelector} from "react-redux";
import {closeDrawer, drawerData, setDrawerData, tenantOpen} from "../reducers/drawerReducer";
import {reloadMain, reloadMainContent} from "../reducers/mainContentReducer";

const CreateTenant = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const open = useSelector(tenantOpen)
    const tenantData = useSelector(drawerData)
    const reload = useSelector(reloadMain)
    const dispatch = useDispatch()
    const { sendRequest} = useHttp();

    const initialValues = {
        name: tenantData?.name ?? "",
        address: tenantData?.address ?? "",
        city: tenantData?.city ?? ""
    };

    const handleFormSubmit = (values) => {
        const tenantDataNew = {...values};
        sendRequest(
            {
                url: tenantData ? 'tenant/' + tenantData.id : 'tenant/',
                method: tenantData ? 'PATCH' : 'POST',
                body: {...tenantDataNew}
            },
            {
                message: {
                    success: tenantData ? 'Radnja uspešno izmenjena' : 'Radnja uspešno kreirana',
                    error: tenantData ? 'Radnja nije izmenjena' : 'Radnja nije kreirana'
                }
            },
            {
                success: (response) => {
                    dispatch(reloadMainContent())
                    dispatch(closeDrawer('tenant'))
                }
            }
        );
    };
    return (
        <Box m={2}>
            <Drawer
                anchor={"right"}
                open={open}
                onClose={() => {
                    dispatch(closeDrawer('tenant'));
                    dispatch(setDrawerData(null))
                }}
                PaperProps={{
                    sx: {
                        width: "30%",
                        background: `${colors.primary[400]} !important`
                    }
                }
                }
            >
                <DialogTitle className="DrawerDialog">{tenantData ? 'Izmena radnje' : 'Dodavanje radnje'}</DialogTitle>
                <Formik
                    onSubmit={handleFormSubmit}
                    enableReinitialize={true}
                    initialValues={{...initialValues}}
                    validationSchema={checkoutSchema}
                >
                    {({
                          values, errors, setFieldValue, touched, handleBlur, handleChange, handleSubmit,
                      }) => (<form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            padding="40px"
                            sx={{
                                "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <Textfield
                                type="text"
                                label="Naziv radnje"
                                value={values.name}
                                name="name"
                                sx={{gridColumn: "span 4"}}
                            />
                            <Textfield
                                type="text"
                                label="Adresa"
                                value={values.address}
                                name="address"
                                sx={{gridColumn: "span 4"}}
                            />
                            <Textfield
                                type="text"
                                label="Grad"
                                value={values.city}
                                name="city"
                                sx={{gridColumn: "span 4"}} />
                        </Box>
                        <Box display="flex" justifyContent="end" mr="40px" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                {tenantData ? 'Izmeni radnju' : 'Dodaj novu radnju'}
                            </Button>
                        </Box>
                    </form>)}
                </Formik>
            </Drawer>
        </Box>
    )
        ;
};

const checkoutSchema = yup.object().shape({
    name: yup.string().required("Obavezno polje"),
    address: yup.string().required("Obavezno polje"),
    city: yup.string().required("Obavezno polje"),
});


export default CreateTenant;
